:root {
    --background-color-primary: #DCD7C9;
    --background-color-primary2: #A27B5C;
    --background-color-secondary: #DCD7C9;
    --background-color-secondary2: #d7caa7;
    
    --font-color-dark: #a05b00;
    --primary-color: #007bff;
    --secondary-color: #2C3639;
    --secondary-color2: #3F4E4F;
    --text-color: white;
    --selection-color: #6d438a
}

*{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
::selection {
    background-color: var(--secondary-color2); /* Change to your desired color */
    color: white;
}

/* App.css or your main CSS file */
.skeleton-wrapper {
    padding: 20px;
  }

  /* App.css or your main CSS file */
.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    /* height: fit-content; */
    color: #394548;
  }
  

.wrapper{
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.backgroundCover{
    position: fixed;
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    background-color: #2c363990;
    background-color: #2c3639e8;
    top: 0;
    left: 0;
}

.newNavbar{
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--secondary-color);
    /* justify-content: space-between; */
    align-items: center;
    padding: 2px 10px;
    position: fixed;
    padding-left: 200px;
    transition: all 0.4s ease;
    z-index: 3;
}

#sidebarBut{
    display: none;
    color: white;
    padding: 0 8px;
    cursor: pointer;
    transition: all 1s ease;
}
#sidebarBut :hover{
    transform: scale(1.01);
    color: #aec7c9;
}

.newNavbar .headIcons span{
    color: white;
}

.headIcons i{
    margin: 0 8px;
    color: #afcace;

}

.navheading{
    color: white;
    font-size: 18px;
    font-weight: 450;
    padding: 8px;
}

/* Sidebar CSS Starts */

#mainCover{
    display: none;
    position: fixed;
    background-color: #2c363990;
    z-index: 3;
    width: 100%;
    height: 100%;
}

.sidebar{
    width: 200px;
    position: fixed;
    z-index: 4;
    height: 100%;
    overflow-y: auto;
    background-color: var(--secondary-color);
    transition: all 0.4s ease;
    /* box-shadow: -2px 1px 22px 0.1px rgba(125, 125, 125, 0.649); */
    /* background-image: linear-gradient(180deg, rgb(250, 189, 189), yellow); */
}
.sidebarContainer{
    margin: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: var(--text-color);
    background-color: #364647;
    padding: 4px;
    font-size: 15px;
    /* cursor: pointer; */
}

.sidebarContainer a, .sidebarContainer span{
        display: flex;
        flex-direction: row;
        font-weight: 500;
        font-size: 13px;
        padding: 7px 8px;
        border-radius: 8px;
        text-decoration: none;
        color: var(--text-color);
        cursor: pointer;
}

.sidebarContainer i:hover{
    /* color: #20102b; */
    /* color: #A27B5C; */
}
.sidebarContainer a:hover, .sidebarContainer span:hover{
    color: #ffffff;
    background-color: #2C3639;
}

.sideNames{
    margin-left: 10px;
}


/* Go up css */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background-color: var(--secondary-color2);
    color: white;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    transition: all 0.3s ease;
    z-index: 5;
}

.scroll-to-top:hover {
    background-color: var(--secondary-color);
    transform: scale(1.1);
}
.scroll-to-top:active {
    background-color: var(--secondary-color);
    transform: scale(0.9);
}
/* Go up css ends */

footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.footerTop{
    position: absolute;
    background-color: #2e4248;
    width: 60%;
    height: 70px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    font-weight: 550;
}

.footer{
    width: 100%;
    height: 280px;
    margin-top: 35px;
    padding: 15px;
    padding-top: 50px;
    background-color: #2C3639;

}

.footer {
    width: 100%;
    height: auto;
    padding: 50px 15px;
    background-color: #2C3639;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-column {
    flex: 1 0 11%; /* grow, shrink, basis */
    flex-direction: column;
    margin: 1em;
    color: #fff; /* assuming white text color for visibility against dark background */

}

.footer-column h3 {
    font-size: 14px;
    margin-bottom: 1em;
}
.footer-column a {
    display: block;
    font-size: 12px;
    text-decoration: none;
    color: #c1c1c1;
    list-style-type: none;
    padding: 0;
}

@media (max-width: 600px) {
    .footer-column {
        flex: 1 0 100%;
    }
}

::-webkit-scrollbar {
    margin-left: 2px;
    width: 6px;
    background: var(--secondary-color2) !important; 
}

::-webkit-scrollbar-track {
    width: 12px;
    /* background: var(--secondary-color) !important;  */
}

::-webkit-scrollbar-thumb {
    background: var(--secondary-color) !important; 
    border-radius: 5px;
}

/* Sidebar CSS Ends */

.main{
    width: 100%;
    margin-left: 200px;
    align-items: right;
    align-content: right;
    transition: all 0.4s ease;
    margin-top: 42px;
}

.rightblock img{    
    width: 100%;
}

.flexing{
    display: flex;
    flex-direction: row;
}

/* Main-Home Page CSS ends here */
@media only screen and (max-width: 820px) {
    .footerTop{
        font-size: 17px;
        height: 60px;
    }
    .footer{
        margin-top: 30px;
    }
}

@media only screen and (max-width: 710px) {
    .footerTop{
        font-size: 15px;
        height: 50px;
        width: 65%; 
    }
    .footer{
        margin-top: 25px;
    }
}

@media only screen and (max-width: 618px) {
    .footerTop{
        font-size: 13px;
        height: 40px;
        
    }
    .footer{
        margin-top: 20px;
    }
}

@media only screen and (max-width: 720px) {
    #sidebar{
        width: 0px;
        position: fixed;
        
    }
    #sidebar .sideNames{
        display: none;            
    }
    #sidebarBut{
        display: flex;
        color: white;
        padding: 0 10px 0 18px;

    }
    .main{
       margin-left: 0px;
    }
    .newNavbar{
        padding-left: 0px;
    }
}

@media only screen and (max-width: 480px) {

    .footerTop{
        font-size: 12px;
        height: 40px;
        width: 90%;
    }
    .footer{
        margin-top: 20px;
    }
}