.custom-skeleton {
  height: 20px;
  width: 100%;
  background-color: #f0e6d4;
  /* background-image: linear-gradient(90deg, #ebd3ab, #ebd3ab, #ebd3ab); */
  background-size: 200% 100%;
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
  margin-bottom: 10px; /* Adjust spacing between skeletons */
}
.editing {
  width: 100%; /* Ensure the textarea is 100% of the container width */
  box-sizing: border-box; /* Include padding and border in the width calculation */
}

.checkLinkButton {
  background-color: #cdbc89; /* Set the desired background color */
  color: #fff; /* Set the text color for better contrast */
  border: none; /* Remove the default border */
  padding: 10px 20px; /* Add padding for better appearance */
  border-radius: 5px; /* Add rounded corners */
  cursor: pointer; /* Change the cursor to a pointer when hovering */
  text-decoration: none; /* Remove underline from text */
  font-size: 16px; /* Increase the font size */
  display: inline-block; /* Ensure the button has a proper block layout */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.checkLinkButton:hover {
  background-color: #b6a46f; /* Slightly darker shade for hover effect */
}


.link-input {
  width: 100%; /* Match the width of the textarea */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  padding: 8px; /* Add some padding for better appearance */
  margin-top: 10px; /* Add space between the textarea and the input field */
}

@keyframes loading {
  0% {
	background-position: 200% 0;
  }
  100% {
	background-position: -200% 0;
  }
}