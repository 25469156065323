:root {
    --background-color-primary: #DCD7C9;
    --background-color-primary2: #A27B5C;
    --background-color-secondary: #DCD7C9;
    --background-color-secondary2: #d7caa7;
    
    /* --font-color-dark: #a05b00; */
    --font-color-dark: #6e3f02;
    --primary-color: #007bff;
    --secondary-color: #2C3639;
    --secondary-color2: #3F4E4F;
    --text-color: white;
    --selection-color: #6d438a
}

.uploadHeading{
    font-size: 24px;
    margin: 20px 10px;
    font-weight: 550;
    color: #f3f3f3;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-color2);
}

.uploadContainer{
    position: relative;
    margin: 12px;
    margin-top: 16px;
    min-height: 90vh;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    background-color: var(--background-color-secondary2);
    overflow: hidden;
}

.basicInfoContainer{
    display: flex;
    flex-direction: column;

    /* overflow: hidden; */
}
.profilebanner{
    position: relative;
    background-color: var(--background-color-primary2);
    width: 100%;
    aspect-ratio: 8/2;
    min-height: 120px;
    /* padding: 10px; */
}

.profilebanner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profileImgUpload {
    display: none; /* Hidden by default */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.95);
    padding: 30px;
    border-radius: 12px;
    z-index: 10;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.profileImgUpload input[type="file"] {
    margin-bottom: 15px;
    padding: 10px;
    border: 2px dashed #00aaff; /* Theme primary color */
    border-radius: 5px;
    background-color: #f0f4f8; /* Theme background color */
    font-size: 16px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    width: 100%;
}

.profileImgUpload input[type="file"]:hover {
    border-color: #007acc; /* Theme hover color */
}

.profileImgUpload button {
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    background-color: #00aaff; /* Theme primary color */
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    width: 100%;
    max-width: 200px;
}

.profileImgUpload button:hover {
    background-color: #007acc; /* Theme hover color */
}

.editBut {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    color: var(--secondary-color);
    border-radius: 50%;
    width: 32px;
    aspect-ratio: 1/1;
    justify-content: center;
    background-color: var(--background-color-secondary);
    padding: 8px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.2s ease;

}

.editBut:hover{
    transform: scale(1.05);
}
.profileImg{
    position: relative;
    z-index: 1;
    width: 140px;
    height: 140px;
    aspect-ratio: 1/1;
    margin: 2px 28px;
    margin-top: -94px;
    background-color: #2C3639;
    border-radius: 50%;   
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.2);
}
.profileImg img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;  
}

.profileImg .editBut{
    top: 3px;
    right: 3px;
}
.basicInfo{
    position: relative;
    margin: 4px 16px;
}
.basicInfo .editBut{
    top: -10px;    
    right: -5px;
}

.basicInfo .name{
    margin-right: 20%;
    padding: 4px;
    font-size: 24px;
    font-weight: 600;
    
}
.description{
    font-size: 14px;
    padding: 4px;
    font-weight: 450;
}
.basicInfo .address{
    padding: 4px;
    font-size: 14px;
    font-weight: 550;
}
.basicInfo .contact{
    color: var(--font-color-dark);
    padding: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}
.contact .contactValue:hover{
    text-decoration: underline;
}
.contact .contactValue:active{
    color: var(--secondary-color);
    font-weight: 550;
}
.contact .contactInfo{
    font-weight: 650;
    color: #2C3639;
    cursor: default;
}

#basicEditContainer {
    /* z-index: 7; */
    /* position: fixed; */
    /* position: absolute; */
    position: relative;
    display: none;
    /* margin-left: 60px; */
    overflow-y: auto;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    height: fit-content;
    width: 96%;
    margin: 2%;
    background-color: var(--background-color-primary);
    border-radius: 12px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
}

.editContainer{
    display: flex;
    flex-direction: column;
    margin: 20px;
}
.editContainer label{
    font-size: 16px;
    font-weight: 520;
    color: var(--secondary-color);
    margin: 4px;
    margin-top: 12px;
}
.editContainer input, .editContainer textarea{
    border-radius: 8px;
    border: 0px solid black;
    min-height: 32px;
    padding: 4px 8px;
    margin: 4px;
    font-size: 14px;
    font-weight: 450;
    height: fit-content;
}


.profileSection{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 28px 10px;
    padding: 8px;
    padding-top: 22px;
    min-height: 50px;
    border-radius: 8px;
    background-color: var(--background-color-secondary);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1;
    cursor: pointer;
}

.profileSection p, .expandedParameter p{
    color: var(--secondary-color);
    border: 1.5x solid #3f4e4fb4;
    padding: 4px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}

.profileSection .editing, .expandedParameter .editing{
    outline: 1.5px solid #2C3639;
}



.profileSection:hover{
    /* transform: scale(1.002); */
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
}

.profileSection .editBut{
    top: -10px;
    right: -4px;
}

.expandedParameter{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 18px;
    margin-top: -30px;
    padding: 8px;
    height: fit-content;
    max-height: 0px;
    border-radius: 0 0 8px 8px;
    background-color:#cfc6af;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease;
    overflow: hidden;
    align-items: center;
}
.expandedParameter p{
    width: 100%;
}
.expandedParameter.expand{
    max-height: 700px;
    overflow-y: auto;
}
.expandedParameter .expandGuide{
    height: 14px;
    margin-top: -18px;
    font-weight: 550;
    font-size: 14px;
    transition: all 0.3s ease;
}
.paraPoints{
    width: 100%;
    padding: 4px;
    background-color: var(--background-color-primary);
}
.expandedParameter input{
    width: 289px;
    margin: 4px 8px;
    background-color: var(--background-color-primary);
    color: var(--secondary-color);
    border: 0px solid black;
    border-radius: 8px;
    padding: 4px 8px;
    outline: none;
}

legend{
    position: absolute;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    top: -18px;
    color: var(--secondary-color);
    padding: 4px 12px;
    margin: 4px;
    font-weight: 580;
    border-radius: 8px;
    background-color: var(--background-color-primary);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.3s ease;
}

legend:hover{
    transform: scale(1.04);
}

.eventSection{
    flex-direction: row;
    flex-wrap: wrap;
}
.eventSection h5{
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: 600;
}

.eventParent{
    width: 50%;
    padding: 10px;
}

.eventPost{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--background-color-secondary);
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    gap: 16px;
}
.eventImg{
    width: 80px;
    height: 80px;
    background-color: #c2b591;
    margin: 5px;
    aspect-ratio: 1/1;
    border-radius: 8px;
    overflow: hidden;
}
.eventImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.eventDetails{
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 16px;
    height: 100%;
}
.eventPostTitle{
    font-size: 1.5em;
    font-weight: bold;
    color: var(--font-color-dark);
}
.eventPostDescription{
    height: 56px;
    font-size: 11px;
    font-weight: 450;
    color: var(--font-color-dark);
    padding: 0 4px;
    overflow: hidden;
    overflow-y: scroll
}

.eventUpload{
    display: flex;
    width: 90%;
    margin: 10px 5%;

}

.custom-skeleton {
    margin-bottom: 8px;
    border-radius: 12px;
}

.eventUpload input, .eventUpload textarea{
    width: 90%;
    border: 0px solid black;
    border-bottom: 1px solid var(--secondary-color);
    background-color: var(--background-color-primary);
    outline: none;
    padding: 4px;
    margin: 8px;
    height: 34px;
}
.eventUpload button{
    width: 80px;
    border: 1px solid black;
    border: none;
    border-radius: 12px;
    background-color: var(--secondary-color);
    color : white;
    padding: 4px;
    font-size: 14px;
    margin: 10px;
}

.eventUpload textarea{
    min-height: 34px;
    max-height: 160px;
}
.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    margin: 12px 10px;
    width: 30%;
    align-items: center;
    align-content: center;
    border: 2px dashed #b49f85;
    border-radius: 10px;
    background-color: #cac1a9;
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .file-upload-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #70450d;
    cursor: pointer;
  }
  
  .file-upload-label p {
    margin: 10px 0 0 0;
    font-size: 15px;
    font-weight: 450;
    color: #70450d;
    cursor: pointer;
  }
  
  .file-upload-input {
    display: none;
  }
  
  .file-upload-container:hover {
    
    background-color: #b6ae99;
  }
  


/* Placement Section starts */

.placementSection, .publicationSection{
    display: flex;
    position: relative;
    flex-direction: column;
    margin: 10px;
    padding: 4px;
    border-radius: 8px;
    background-color: var(--secondary-color);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}
.placementSection thead, .publicationSection thead{
    display: flex;
    padding: 4px;
    color: white;
}

.placementSection thead th, .publicationSection thead th{
    text-align: center;
    font-weight: 450;
    width: 22.5%;
}

.placementBody tr:nth-child(even), .publicationSection tr:nth-child(even){
    background-color: #f2f2f2; 
}
.placementBody tr:nth-child(even), .publicationSection tr:nth-child(even){
    background-color: #f2f2f2;
}

.publicationSection td, .publicationSection th{
    width: 100px;
}

.recordCell{
    display: flex;
    color: var(--secondary-color);
    width: 100%;
    border-radius: 4px;
}
.recordCell td{
    text-align: center;
    align-items: center;
    padding: 4px;
    font-weight: 550;
    letter-spacing: 1px;
    width: 22.5%;
}
.recordCell td:first-child, .placementSection th:first-child, .placementSection th:first-child{
    width: 40%;
}

.recordCell td:last-child, .placementSection th:last-child, .placementSection td:last-child{
    width: 15%;
}
.recordCell i{
    border-radius: 50%;
    padding: 6px;
    transition: all 0.2s ease;
}

.recordCell i:hover{
    background-color: #4d555843;
    color: var(--font-color-dark);
    cursor: pointer;
}
.recordCell i:active{
    color: black;
    transform: scale(0.9);
}

.placementYear{
    padding: 4px 2px;
    font-size: 24px;
    font-weight: 550;
}



.placementBody, .publicationBody{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4px;
    /* max-height: 100px; */
    border-radius: 5px;
    background-color: var(--background-color-primary);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    
}

.placementBody .actualData{
    min-height: 48px;
    max-height: 400px;
    overflow-y: scroll;
}

.addCell{
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    bottom: 10px;
    background-color: var(--secondary-color);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;   
    transition: all 0.3s ease;
}

.addCell.save{
    left:10px

}

.addCell:hover{
    transform: scale(1.1);
    color: white;
}

.placementInputArea{
    box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.2);
    margin-top: 4px;
}

.recordInput{
    display: flex;
    height: 0px;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.recordInput.active{
    height: 40px;
    padding: 4px;
    margin: 10px 0;
    transition: all 0.3s ease;
}

.recordInput div{
    width: 25%;
}
.recordInput input{
    width: 96%;
    font-weight: 500;
    padding: 4px 8px;
    border: 0px solid black;
    border-radius: 8px;
    outline: none;
}

.saveDataBut{
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 24px;
    bottom: 24px;
    z-index: 3;
    background-color: var(--secondary-color);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;   
    transition: all 0.3s ease;
}
.saveDataBut.refresh{
    bottom: 88px;
}

.saveDataBut:hover{
    transform: scale(1.1);
    background-color: #3F4E4F;
    color: white;
}
.saveDataBut:active{
    transform: scale(0.9);
    background-color: #2C3639;
    color: white;
}

/* For laptop devices */
@media (max-width: 1441px) {
    
}

/* For tablet devices */
@media (max-width: 1024px){
    .profilebanner{
        height: 150px;
    }
    .profileImg{
        width: 130px;
        height: 130px;
        margin-top: -80px;
    }
    .eventPostTitle{
        height: 20px;
        font-size: 14px;
    }
    .eventPostDescription{
        height: 60px;
        font-size: 10px;
    }
}

/* For mobile devices */
@media (max-width: 600px) {
    .profilebanner{
        height: 134px;
    }
    .profileImg{
        width: 120px;
        margin-top: -74px;
    }

    .eventParent{
        width: 100%;
    }

    .placementSection th, .placementSection td{
        font-size: 12px;
    }
    .placementYear{
        padding: 4px 2px;
        font-size: 20px;
        font-weight: 550;
    }
    
    .recordInput.active{
        height: 35px;
        padding: 4px;
        margin: 10px 0;
        transition: all 0.3s ease;
    }
    .recordInput input{
        font-size: 12px;
    }
    #basicEditContainer{
        height: 70%;
        width: 90%;
    }

    .eventUpload{
        width: 100%;
        margin: 10px 0;
    }
    
    .file-upload-container{
        width: 40%;
    }


    .file-upload-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #70450d;
        cursor: pointer;
      }
      
      .file-upload-label p {
        margin: 2px 0 0 0;
        font-size: 12px;
        font-weight: 450;
        color: #70450d;
        cursor: pointer;
      }
}

@media (max-width: 400px){
    .expandedParameter input{
        width: 100%;
        margin: 4px 0;
    }
    .recordCell td, .placementSection thead th{
        overflow: hidden;
        width: 28%;
    }
    .recordCell td:first-child, .placementSection th:first-child, .placementSection th:first-child{
        width: 30%;
    }
    
    .recordCell td:last-child, .placementSection th:last-child, .placementSection td:last-child{
        width: 14%;
    }
}