/* :root {
    --background-color-primary: #ffffff;
    --background-color-secondary: #dadada;
    --font-color-dark: #a05b00;
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --text-color: #333333;
  } */


  *{
    margin: 0;
}

body{
    margin: 0;
    background-color: var(--background-color-primary);
}


/* Navigation bar css starts here */
/* 
.navbar {
    background-color: #333;
    color: var(--font-color-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: var(--background-color-primary);
    box-shadow: 5px 5px 12px #cbcbcbb3;
    justify-content: center;
    padding: 0 16px;
    align-items: center;
    z-index: 1;
  }
  
  .navbar-logo {
    color: var(--font-color-dark);
    font-size: 1.5em;
  }
  
  .navbar-links {
    display: flex;
    gap: 20px;
    overflow: hidden; 
    max-height: 1000px;

    display: flex;
    flex-direction: row;
    text-decoration: none;
    list-style-type: none;
    align-items: center;
  }
  
  .navbar-links a {
    color: var(--font-color-dark);
    text-decoration: none;
  }

  .navbar-links a:hover {
    color: #000000;
    text-decoration: none;
  }
  
  .navbar-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--font-color-dark);
    font-size: 1.5em;
    cursor: pointer;
  } */

/* Navigation bar css ends here */



.about-text{
    padding: 4px;
}


.heading{
    color: #2C3639;
    font-size: 24px;
    font-weight: 550;
    padding: 8px; 
}

.subheading{
    color: var(--secondary-color2);
    font-size: 18px;
    font-weight: 500;
}

.about-text p{
    color: var(--secondary-color2);
}

.block1{
    /* background-image: url('../res/images/b.jpg'); Use the Buransh flower image */
    background-size: cover; /* Ensure the image covers the entire background */
    padding: 4px 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--background-color-primary);
    overflow: hidden;
}

.leftblock{
    width: 48%;
}

.leftblock img{
    width: 220px;
}

.block{
    
    background-color: #d7caa7;
    width: 100%;
    margin-top: 50px;
    margin-right: -200px;
    border-radius: 16px;
    padding: 18px;
    padding-right: 200px;
    text-wrap: wrap;
}

.search-bar {
    margin-top: 20px; /* Adjust the top margin to set the gap from the top */
	margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Adds space between the icon and input */
  }
  
  .search-icon {
    font-size: 24px; /* Adjust the size of the icon */
    color: #333; /* Optional: change the color of the icon */
  }
  
  .search-bar input {
    width: 50%; /* Adjust the width as needed */
    max-width: 500px; /* Optional: set a maximum width */
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc; /* Optional: add border for better visibility */
    border-radius: 5px; /* Optional: add border radius for rounded corners */
    outline: none; /* Optional: remove the default outline */
    padding-left: 35px; /* Adjust padding to make space for the icon inside the input */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"%3E%3Ccircle cx="11" cy="11" r="8"%3E%3C/circle%3E%3Cline x1="21" y1="21" x2="16.65" y2="16.65"%3E%3C/line%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: 10px center;
  }

  
.homewrapper{
    display: flex;
    width: 100%;
    
    margin-top: 50px;
    border-radius: 16px;
    padding: 0 0%;
    flex-wrap: wrap;
}
.homeminiblock{
    display: flex;
    background-color: var(--background-color-secondary2);
    width: 48%;
    margin: 1%;
    height: 74px;
    font-weight: 500;
    border-radius: 22px;
    padding-left: 18px;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    /* overflow: hidden; */
}
.homeminiblock div{
    /* background-color: #2C3639; */
    height: 100%;

}
.homeminiblock span{
    background-color: var(--background-color-secondary);
    padding: 8px;
    border-radius: 50%;
    height: 100%;
}

.block button{
    background-color: #a37752;
    border: none;
    padding: 6px 14px;
    border-radius: 16px;
    font-size: 14px;
    margin: 8px;
    color: #3b1900;
    font-weight: 550;
    align-items: center;
    transition: all 0.2s ease;
}

.block button:hover{   
    transform: scale(1.05);
    background-color: #885b36;
    background-color: #b47b4c;
}
.block button:active{   
    transform: scale(1);
    background-color: #b47b4c;
    background-color: #885b36;
}
.block i{
    margin-left: 4px;
}




.buttons button{
    background-color: #331b44;
    padding: 6px 16px 6px 16px;
    margin-right: 16px;
    
    border-radius: 38px;
    color: var(--background-color-primary);
}
.rightblock{
    width: 52%;
}
#righblock img{
    width: 100%;
}
.homeimage{
    width: 100%;
}



/* Home new carousel by self Starts */
.homeParaParent {
    width: 100%;
    height: 275px;
    background-color: #2C3639;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 16px;
}

.paraButtons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 10px;
}

.paraButtons span {
    background-color: var(--secondary-color2);
    margin: 0 4px;
    border-radius: 16px 16px 0 0;
    font-size: 12px;
    text-align: center;
    justify-content: center;
    align-content: center;
    padding: 4px 12px;
    height: 44px;
    color: white;
    transition: all 0.4s ease;
}

.paraButtons .active {
    padding: 2px 16px;
    transform: scaleX(1.1);
    background-color: #b17a4d;
    background-color: var(--background-color-primary);
    color: #2C3639;
    font-weight: 550;
}

.homeparameters {
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 275px;
    color: white;
    background-color: #f1d997;
    border-radius: 12px;
    transition: all 0.6s ease-in-out;
    overflow: hidden;
    justify-content: space-between;
}

.homeparameters .paraContent {
    background-color: var(--background-color-primary);
    padding: 8px;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease-in-out;
    color: black;
}

.homeparameters .hider {
    background-color: var(--background-color-primary);
    padding: 8px;
    transition: all 0.6s ease-in-out;
    position: absolute;
    height: 100%;
    width: 0%;
    top: 0;
    right: 0;
}

.homeparameters .paraContent h4 {
    color: var(--secondary-color);
    margin-left: 12px;
    margin-top: 4px;
}

.homeparameters .paraContent p {
    color: var(--secondary-color);
    height: 120px;
    text-wrap: wrap;
    overflow: hidden;
    font-size: 14px;
}

.homeparameters .paraContent div {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 12px;
}

.homeparameters .paraContent a {
    border: none;
    background-color: var(--secondary-color2);
    border-radius: 16px;
    padding: 8px 12px;
    font-size: 12px;
    color: white;
    text-decoration: none;
    transition: all 0.2s ease;
}

.homeparameters .paraContent a:hover {
    background-color: var(--secondary-color);
    transform: scale(1.05);
}

.homeparameters .paraContent a:active {
    background-color: var(--secondary-color2);
    transform: scale(0.95);
}

.homeuniversity {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 275px;
    color: white;
    background-color: #2C3639;
    border-radius: 16px;
    padding: 8px;
    overflow: hidden;
    align-items: center;
}

.homeuniversity img {
    width: fit-content;
    height: 100%;
}

#universityName {
    font-weight: 550;
    align-items: center;
    justify-content: center;
    padding-bottom: 6px;
}


/* Home new carousel by self Ends */


/* CardStyle CSS  Starts*/
.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 18px 10px;
}

.card {
    display: flex;
    width: 30%;
    margin: 5px 0;
    height: 100px;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px;
    background-color: var(--secondary-color);
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-section1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 22px;
}
.card-section2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 42px;
}
.card span{
    height: 100%;
    color: white;
    font-size: 10px;
    font-weight: 550;
    width: justify-content;
    letter-spacing: 2px;
}
.card .statchange{
    display: flex;
    border-radius: 40px;
    width: 70px;
    background-color: rgb(228, 80, 0);
    align-items: center;
    justify-content: center;
}

.card .stats, .card .stats-option{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    font-size: 22px;
    width: fit-content;
    
}
.card .stats-option{
    border-radius: 50%;
    align-items: center;
    padding: 5px;
    padding-left: 6px;
    transition: all 0.4s ease;
    cursor: pointer;
} 
.card .stats-option:hover{
    background-color: var(--secondary-color2);
}







/* CardStyle CSS  Ends*/

::-webkit-scrollbar {
    width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
    width: 10px;
    background: #fcf1dc; /* Track color */
}

::-webkit-scrollbar-thumb {
    background: #9f8b67; /* Thumb color */
    border-radius: 5px; /* Rounded corners */
}
@media only screen and (max-width: 1251px){
    .miniCardParent{
        width: 33.33% !important;
    }
}

@media only screen and (max-width: 991px){
    .block1, .block2{
        flex-direction: column;
    }
    .heading{
        font-size: 38px;
    }
    .block{
        margin-right: -235px;
    }
    .leftblock{

        img{
            width: 255px;
        }
        button{
            font-size: 18px;
        }

    }

    .leftblock, .rightblock{
        width: 100%;

    }

    .homeParaParent{
        width: 100%;
    }
    .homeuniversity{
        width: 100%;
    }

    .about-text{
        padding: 20px;
    }
   
    .miniCardParent{
        width: 50% !important;
        margin-bottom: 7px !important;
    }
}

@media only screen and (max-width: 793px) {
    /* .block1, .block2{
        padding: 10px;
    } */
    .heading{
        font-size: 36px;
    }
    .subheading{
        font-size: 15px;
    }

    .innerblock, .innerParameterBlock{
        width: 90% !important;
    }

    .parameters{
        display: flex;
        flex-direction: row;
    }
    .parametersRow{
        display: flex;
        width: 50%;
        flex-direction: column !important;
        
    }
    
    .parametersBlockParent{
        width: 100% !important;
        height: 140px;
    }
    .parametersBlock{
        font-size: 12px;
    }
    .miniCardParent{
        width: 100% !important;
        margin-bottom: 10px !important;
    }
    .card{
        width: 44%;
    }
    .card .stats{
        font-size: 18px;
    }

}


@media only screen and (max-width: 672px) {
    .heading{
        font-size: 30px;
    }
    .subheading{
        font-size: 15px;
    }
    .rightblock img{
        margin: 5%;
        width: 90%;
    }

}

@media only screen and (max-width: 580px) {

    .block1{
        .leftblock{
            .block{
                padding: 12px;
                padding-right: 200px;
                margin-right: -200px;
                .heading{
                    font-size: 20px;
                }
            }
            img{
                width: 200px;
            }
        }
    }
    .heading{
        font-size: 24px;
    }
    .leftblock img{
        width: 210px;
    }

    .homeParaParent{
        margin-bottom: 12px;

        .paraButtons{
            padding: 0 6px;
            .params{
                font-size: 10px;
                text-wrap: wrap;
                overflow: hidden;
                padding: 7px 4px;
                align-content: center;
            }
        }
        .paraContent{
            border-radius: 2px;
            .contentHeading{
                font-size: 22px;
            }
            a{
                font-size: 10px;
            }
        }
    }  
    .homeuniversity{
        margin-bottom: 12px;
    }

    /* Minimising Navbar Code is to be done here... */

    /* .navbar-links {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: var(--background-color-secondary);
        width: 100%;
        transition: all 0.3s ease-in;
        max-height: 0; 
      }
    
      .navbar-links.active {
        display: flex;
        padding: 10px 0;
        max-height: 200px;
      }
    
      .navbar-links a {
        padding: 0px 20px;
        display: block;
      }

    
      .navbar-toggle {
        display: block;
      }

      .miniCardParent{
        width: 100% !important;
        margin-bottom: 10px !important;
    } */

    /* Minimising Sidebar Code is to be done here... */
    
}

@media only screen and (max-width: 480px) {

    .block1{
        .leftblock{
            justify-content: space-between;
            .block{
                padding: 8px;
                padding-right: 160px;
                margin-right: -200px ;
                .heading{
                    font-size: 18px;
                }
                button{
                    font-size: 12px;
                }
            }
            img{
                width: 160px;
            }
        }
        .rightblock{
            .homeminiblock{
                font-size: 14px;
            }
            
        }
    }

    .parameters{
        flex-direction: column;
    }
    .parametersRow{
        width: 100%;
    }
    .parametersBlockParent{
        height: fit-content;
    }
    .parametersBlock{
        font-size: 18px;
    }
}


@media only screen and (max-width: 340px) {
    .heading{
        font-size: 14px;
    }   
    .card{
        width: 94%;
    }
    
}


/* Placement CSS */
@media only screen and (max-width: 820px){
    .placementCardParent{
        width: 33.33%;
    }
}
@media only screen and (max-width: 620px){
    .placementCardParent{
        width: 50%;
    }
}

@media only screen and (max-width: 417px){
    .placementCardParent{
        p{
            font-size: 11px;
        }
        .placementCard{
            width: 150px;
            height: 190px;
            .placementImg{
                width: 140px;
                height: 140px;
                img{
                    width: 140px;
                    height: 140px;
                }
            }
        }
    }
    .app-container{
        width: 100%;
    }
}

@media only screen and (max-width: 320px){
    .placementCardParent{
        width: 100%;
        p{
            font-size: 14px;
        }
        .placementCard{
            width: 200px;
            height: 240px;
            .placementImg{
                width: 190px;
                height: 190px;
            }
        }
    }
    .app-container{
        width: 100%;
    }
    .card{
        width: 100%;
    }
}