.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    /* background-color: var(--background-color-primary); */
    background-color: var(--secondary-color2);
    background-color: var(--background-color-primary);
}

.alert-box, .login-alert-box{
    display: none;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    height: fit-content;
    color: rgb(74, 1, 1);
    font-size: 12px;
    background: rgb(216, 104, 104);
    justify-content: center;
    align-items: center;
}

.register-form h2{
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 550;
}

.register-form {
    width: 300px;
    padding: 30px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    background-color: var(--background-color-primary);
    background-color: #d5ccb2;
}

.register-form .logo {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    background: url('../res/images/kedarnath.jpg') no-repeat center;
    border-radius: 50%;
    background-color: #2e383f;
    background-size: contain;
}



.register-form input, .register-form textarea, .register-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    background: #4b5558;
    color: white;
    border-radius: 5px;
    transition: all 0.3s ease;
    outline: none;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
}
/* Change the placeholder text color to white */
.register-form input::placeholder, .register-form textarea::placeholder {
    /* change the color to little dull than white...but not too dull */
    color: #dddbdb;
    
}

.register-form input:hover, .register-form textarea:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
}

.register-form input:focus, .register-form textarea:focus {
    border-color: var(--secondary-color);
    box-shadow: 0 0 5px rgba(26, 32, 36, 0.5);
}

.register-form button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, #007bff, #33ccff);
    background: linear-gradient(to left, #495c62, #2e383f);
    color: #fff;
    cursor: pointer;
    margin-bottom: 10px;
    box-shadow: 0 10px 20px -10px rgba(0,123,255,.25);
    transition: all 0.3s ease;
}

.register-form button:hover {
    background: linear-gradient(to right, #495c62, #2e383f);
    box-shadow: 0 0 5px rgba(26, 32, 36, 0.5);
    /* transform: translateY(-2px); */
    transform: scale(1.01);
}

.register-form button:active {
    transform: translateY(0);
    box-shadow: 0 5px 10px -5px rgba(0,123,255,.25);
}
.register-form .options {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin: 15px 0;
}

.register-form .options a {
    color: #004fa2;
    text-decoration: none;
    transition: all 0.3s ease;
}

.register-form .options a:hover {
    color: #011f40;
    font-weight: 550;
    /* text-decoration: underline; */
}