:root {
    --background-color-primary: #DCD7C9;
    --background-color-primary2: #A27B5C;
    --background-color-secondary: #DCD7C9;
    --background-color-secondary2: #d7caa7;
    
    --font-color-dark: #a05b00;
    --primary-color: #007bff;
    --secondary-color: #2C3639;
    --secondary-color2: #3F4E4F;
    --text-color: white;
    --selection-color: #6d438a
}

.mission-container {
    display: flex;
    position: relative;
    background-color: var(--secondary-color);
    background-color: #d7caa7;
    margin: 18px 20px;
    margin-right: 70px;
    margin-top: 90px;
    border-radius: 22px;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 6px;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .owner-photo img {
    position: absolute;
    top: -55px;
    right: -50px;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    object-fit: cover;
    box-shadow: -2px 5px 10px 0 rgba(0, 0, 0, 0.2);
  }
  
  .details-container {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    padding-right: 100px;
  }

.details-container h1{
    font-size: 30px;
    font-weight: 520;
    margin: 10px 10px;
}
.details-container p{
    color: #2C3639;
    color: #532f01;
    font-size: 16px;
    font-weight: 450;
}
.under-bar{
    width: 70px;
    padding: 3px;
    border-radius: 10px;
    margin-left: 12px;
    background-color: #2C3639;
    margin-bottom: 10px;
}