.instituteHeading {
    display: flex;
    margin: 18px 18px 0 18px;
    font-weight: 600;
    background-color: #cdbc89;
    border: 1px solid #c4b793;
    font-size: 20px;
    padding: 4px 14px;
    border-radius: 20px;
    justify-content: center;
    align-content: center;
    justify-self: center;
}



.ResearchLinkSection {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
}

.left-section,
.right-section {
  width: 45%;
  word-wrap: break-word;
}

.vertical-line {
  width: 2px;
  background-color: #ccc;
  margin: 0 20px;
}

.editingSection, .displaySection {
  display: flex;
  align-items: flex-start;
}

textarea {
  width: 100%;
}

input[type="text"] {
  width: 100%;
}




.instituteContent {
    margin: 12px;
    padding: 6px 18px;
    font-weight: 480;
    font-size: 14px;
    color: black;
}

.view-more {
    background-color: #2c3639;
    padding: 5px 10px;
    margin: 12px 0;
    border-radius: 12px;
    font-size: 12px;
    color: white;
    transition: all 0.3s ease;
    cursor: pointer;
}

.view-more:hover {
    transform: scale(1.3);
    background-color: #425155;
}

.view-more a {
    text-decoration: none;
    color: white;
}

.app-container {
    width: 50%;
}

.progress-bar-container {
    margin-bottom: 10px;
}

.progress-bar {
    width: 100%;
    background-color: var(--background-color-secondary2);
    border-radius: 5px;
    overflow: hidden;
}

.progress {
    height: 8px;
    background-color: var(--secondary-color2);
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}

.progress-label {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #543e01;
}

.progress-title {
    color: var(--secondary-color);
    font-size: 16px;
}

.progress-num {
    border-radius: 50%;
    padding: 5px 6px;
    font-weight: 600;
    color: black;
}

.researchSection {
    background-color: #f8f4e7;
    border: 1px solid #cdbc89;
    padding: 16px;
    border-radius: 5px;
}

.researchTitle {
    font-weight: bold;
    margin-bottom: 5px;
}

.researchLinkSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.editingSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.displaySection {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.researchSection {
    position: relative;
    padding: 10px;
    background-color: #f7f4e9;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.displaySection {
	margin-top:	30px;
    margin-bottom: 10px;
}

.checkLinkButton {
    background-color: #cdbc89;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    display: block;
    margin-top: 10px;
    width: 100%;
}

.checkLinkButton:hover {
    background-color: #b8a76c;
}

.editButtonSection {
    position: absolute;
    top: 10px;
    right: 10px;
}

.editButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.editButton img {
    width: 20px;
    height: 20px;
}


.placementHeaders {
    background-color: #f0e6d4;
    display: flex;
    width: fit-content;
    margin: 22px 16px;
    height: 44px;
    font-weight: 500;
    border-radius: 22px;
    padding-left: 18px;
    padding-right: 0px;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}

.placementHeaders div {
    margin-left: 12px;
    height: 100%;
}

.placementHeaders span {
    background-color: var(--background-color-secondary);
    padding: 8px;
    border-radius: 50%;
    height: fit-content;
}

.placementData {
    margin: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.placementCardParent {
    display: flex;
    width: 25%;
    justify-content: center;
}

.placementCard {
    background-color: #f0e6d4;
    justify-self: center;
    display: flex;
    flex-direction: column;
    margin: 20px;
    width: 200px;
    height: 240px;
    background-color: var(--secondary-color);
    border: 1px solid rgba(0, 0, 0, 0.305);
    border-radius: 10px;
    padding: 0 5px;
}

.placementCard p {
    color: rgb(255, 255, 255);
    font-weight: 550;
    font-size: 14px;
    text-align: center;
    height: 15px;
    margin-bottom: 10px;
    align-content: center;
}

.placementImg {
    width: 190px;
    height: 180px;
    border-radius: 12px;
    background-color: antiquewhite;
    overflow: hidden;
}

.placementImg img {
    width: 190px;
    height: 190px;
}

.placementSection {
    background-color: #f0e6d4;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid #c4b793;
}

.placementYear {
    font-weight: 600;
    font-size: 18px;
    color: black;
    margin-bottom: 10px;
}

.linkSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.linkSection a {
    color: black;
    text-decoration: none;
    font-weight: 500;
}



.editSection button {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #cdbc89;
    color: black;
    font-weight: 500;
}

.editSection button:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

.toggleButton {
  border: none;
  background-color: #007bff; /* Adjust color as needed */
  color: white;
  border-radius: 50%; /* Makes the button circular */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toggleButton {
  border: none;
  background-color: transparent; /* No background color */
  color: black; /* Icon color set to black */
  border-radius: 50%; /* Keeps the button circular */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toggleButton:disabled {
  color: gray; /* Adjust icon color when disabled */
}

.editButton .fa-la {
  font-size: 18px; /* Adjust icon size as needed */
}

.edit {
  /* Optional: Add any specific styles for the edit state */
}

.tick {
  /* Optional: Add any specific styles for the tick state */
}



@media only screen and (max-width: 1050px) {
    .placementCardParent {
        width: 33.33%;
    }
}

@media only screen and (max-width: 837px) {
    .placementCardParent {
        width: 50%;
    }

    .app-container {
        width: 100%;
    }
}

@media only screen and (max-width: 417px) {
    .placementCardParent p {
        font-size: 11px;
    }

    .placementCard {
        width: 150px;
        height: 190px;
    }

    .placementImg, .placementImg img {
        border-radius: 4px;
        width: 140px;
        height: 140px;
    }
}

@media only screen and (max-width: 320px) {
    .placementCardParent {
        width: 100%;
    }

    .placementCard {
        width: 200px;
        height: 240px;
    }

    .placementImg img {
        width: 190px !important;
        height: 190px !important;
    }

    .app-container {
        width: 100%;
    }
}
