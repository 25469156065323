/* :root {
    --background-color-primary: #ffffff;
    --background-color-secondary: #F6E7CC;
    --font-color-dark: #a05b00;
    --primary-color: #007bff;
    --secondary-color: #6c757d;
    --text-color: #333333;
  } */

  .innerblock, .innerParameterBlock{
    width: 100%;
    display: flex;
    background-color: #2C3639;
    margin: 18px;
    border: 1px solid black;
    padding: 8px;
    border-radius: 8px;
}
.innerParameterBlock{
    flex-direction: column;
}
.innerblock img{
    width: 100%;
    border-radius: 8px;
}

.innerblockleft{
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: center;
    align-items: center;
}
.belowImgParams{
    width: 100%;
}
.univname{
    color: white;
    padding: 8px;
    background-color: #3F4E4F;
    width: 100%;
    border-radius: 8px;
    margin-top: 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-wrap: wrap;
    transition: all 0.4s ease;
}
.univname:hover{
    transform: scale(1.04);
}
.innerblockright{
    width: 70%;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-primary);
    margin-left: 8px;
    border-radius: 8px;

}
.parameters{
    height: 100%;
    width: 100%;
    background-color: var(--background-color-primary);
    border-radius: 8px;
    padding: 2%;
}
.parametersRow{
    display: flex;
    flex-direction: row;
    height: 50%;
}
.parametersBlockParent{
    width: 33.33%;
}
.parametersBlock{
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 84%;
    width: 84%;
    margin: 8%;
    padding: 15%;
    text-align: center;
    background-color: #3F4E4F;;
    border-radius: 12px;
    transition: all 0.4s ease;
}

.parametersBlock:hover{
    transform: scale(1.1);
    cursor: pointer;
}

.parametersBlock div{
    margin: 7px 0;
    color:rgb(94, 68, 28);
    color: white;
    /* color:#A27B5C; */
}

.parameterDetailsParent{
    display: flex;
    flex-direction: column;
    width: 99%;
    background-color: #A27B5C;
    border-radius: 8px;
    margin: 0.5%;
}

.parameterDetailsParent h5, p{
    margin: 6px 12px;
    color: rgb(255, 255, 255);
}
.parameterDetails{
    margin: 8px;
    justify-content:space-evenly;
}
.parameterDetails p{
    justify-content: right;
    text-align:left ;
}

/* --------------------------------- */

.filter{
    width: 100%;
    height: 50px;
    background-color: #921a1a;
}


/* --------------------------------- */
.miniCardBlock {
    background-color: var(--secondary-color);
    width: 75%;
    height: fit-content;
    font-size: 12px;
    padding: 8px;
    border-radius: 14px;
    box-shadow: 3px 3px 6px 1px rgba(0, 0, 0, 0.392);
    transition: all 0.3s ease;
    text-decoration: none; /* Ensures no underline on the link */
    color: inherit; /* Inherit text color */
    display: block; /* Ensures the whole block is clickable */
}

.miniCardBlock:hover {
    transform: scale(1.02);
}

.miniCardImg {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px;
    background-color: var(--background-color-primary);
}

.miniCardGrandParent {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.miniCardParent {
    display: flex;
    width: 25%;
    padding: 20px;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.univname {
    font-size: 16px; /* Adjust as needed */
    margin: 10px 0; /* Adjust as needed */
    text-align: center; /* Center the text */
}